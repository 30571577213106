$(function () {

  // Responsive
  GLOBAL.actualResizer = 'throttledresize' in jQuery.event.special ? 'throttledresize' : 'resize';
  function responsiveFixes() {
    var ww = $(window)[0].innerWidth || $(window).width(),
        wh = $(window)[0].innerHeight || $(window).height();

    GLOBAL.windowSize = [ww, wh];

    // Prevent fixes if changed only window height
    if ($(document.body).data('originalWW') && $(document.body).data('originalWW') == ww) {
      return;
    }
    $(document.body).data('originalWW', ww);

    // Responsive menu
    // Required: /js/plugins/jquery.event.move.js, /js/plugins/jquery.event.swipe.js
    $('.mobile-menu-link').off('click.ht');
    $('nav').off('.ht');
    if (ww < 768) {
      $('.mobile-menu-link').on('click.ht', function (e) {
        e.preventDefault();
        $(document.body).toggleClass('nav-opened');
      });
      $('nav').on('swipeleft.ht swiperight.ht', function () {
        $('.mobile-menu-link').trigger('click');
      }).on('movestart.ht', function (e) {
        if ((e.distX > e.distY && e.distX < -e.distY) || (e.distX < e.distY && e.distX > -e.distY)) {
          e.preventDefault();
        }
      });
    }
  }
  responsiveFixes();
  $(window).on(GLOBAL.actualResizer, responsiveFixes);




  // Replace checkboxes and radio buttons
  // Required: /js/src/plugins/jquery.inputs.js
  if (GLOBAL.config.inputs.length) {
    GLOBAL.config.inputs.inputs();
  }


  // Replace selectors
  // Required: /js/src/plugins/jquery.stylish-select.js
  if (GLOBAL.config.selectors.length) {
    GLOBAL.config.selectors.sSelect();
  }


  // Carousels
  // Required: /js/src/plugins/jquery.jcarousel.js
  // Optional: /js/src/plugins/jcarousel-plugins/jquery.jcarousel-swipe.js, /js/src/plugins/jcarousel-plugins/jquery.jcarousel-fade.js
  var carouselInit = function (container, options) {
    var
      $carousel = $('.jcarousel-wrapper', container).jcarousel({
        wrap: options.wrap,
        vertical: options.vertical
      }),
      isFadeSupported = $.isFunction($.jcarousel.fn.fade),
      method = isFadeSupported && options.method == 'fade' ? 'fade' : 'scroll';

    $carousel.jcarouselAutoscroll({
      interval: options.autoscrollInterval,
      target: '+=' + options.scrollableItems,
      autostart: !!options.autoscrollInterval,
      method: method // 'fade' not supported here (https://github.com/jsor/jcarousel/issues/806)
    });

    $('.jcarousel-prev', container).on('jcarouselcontrol:active', function () {
      $(this).removeClass('jcarousel-controls-inactive');
    }).on('jcarouselcontrol:inactive', function () {
      $(this).addClass('jcarousel-controls-inactive');
    }).jcarouselControl({
      target: '-=' + options.scrollableItems,
      method: method
    });
    $('.jcarousel-next', container).on('jcarouselcontrol:active', function () {
      $(this).removeClass('jcarousel-controls-inactive');
    }).on('jcarouselcontrol:inactive', function () {
      $(this).addClass('jcarousel-controls-inactive');
    }).jcarouselControl({
      target: '+=' + options.scrollableItems,
      method: method
    });

    if (options.pagination && $carousel.jcarousel('items').length > options.scrollableItems) {
      $('.jcarousel-pagination', container).on('click', function (e) {
        e.preventDefault();
      }).on('jcarouselpagination:active', 'a', function () {
        $(this).addClass('jcarousel-pagination-active');
      }).on('jcarouselpagination:inactive', 'a', function () {
        $(this).removeClass('jcarousel-pagination-active');
      }).jcarouselPagination({
        'item': function (page, carouselItems) {
          return '<a href="">' + page + '</a>';
        },
        method: method
      });
    }

    if ('jcarouselSwipe' in $carousel && options.touchable) {
      var touchConfig = {};
      if (isFadeSupported) {
        touchConfig = {
          draggable: false,
          method: method
        };
      }
      $carousel.jcarouselSwipe(touchConfig);
    }
  };

  $.each(GLOBAL.config.carousels, function (selector, options) {
    if ($(selector).length > 1) {
      $(selector).each(function () {
        carouselInit(this, options);
      });
    } else {
      carouselInit(selector, options);
    }
  });


  // Modal windows
  // Required: /js/src/plugins/jquery.uniloader.js
  var modalInit = function (activator, options) {
    var opts = {};
    if ($(activator).data('modal-node')) {
      opts.node = $(activator).data('modal-node');
    }
    $.overlayLoader(true, $.extend(true, {}, opts, $(activator).data('modal-options') || options));
  };

  for (var i in GLOBAL.config.modals) {
    $(i).data('modal-options', GLOBAL.config.modals[i]).on('click', function (e) {
      e.preventDefault();
      modalInit(this);
    });
  }


  // Popups
  // Required: /js/src/plugins/jquery.unifloat.js
  var popupHandler = function (activator, options) {
    if (!$(activator).length) {
      return;
    }
    var options = options || $(activator).data('popup-options');
    var target = options.rel || '#' + $(activator).attr('id') + '-content',
        self = activator;
    if (options.manipulation && !$(target).data('unifloat-manipulated')) {
      $(document.body).append($(target));
      $(target).data('unifloat-manipulated', true);
    }
    if ($(target).is(':hidden')) {
      $(target).unifloat('show', $.extend(true, {}, options, {
        rel: self
      })).data('unifloat-source', activator);
    } else {
      $(target).hide();
      if (options.onHide) {
        options.onHide(self, target);
      }
    }
  };

  for (var i in GLOBAL.config.popups) {
    $(i).data('popup-options', GLOBAL.config.popups[i]).on('click', function (e) {
      e.preventDefault();
      popupHandler(this);
    });
  }

  // Close popups (modal windows supported)
  // By clicking on an empty area of the window
  $(document.body).on('click', function (e) {
    var isPopup = false;
    $('.popup-activator, .popup, #overlay, .modal').each(function () {
      if ($(e.target).is(this) || $(e.target).parents().is(this)) {
        isPopup = true;
      }
    });
    if ($(e.target).is('.popup-activator') && $(e.target).parents().is('.popup')) {
      $('.popup:visible').each(function () {
        popupHandler($(this).data('unifloat-source'));
      });
      popupHandler(e.target);
      return;
    }
    if (isPopup) {
      return;
    } else {
      $('.popup:visible').each(function () {
        popupHandler($(this).data('unifloat-source'));
      });
    }
  });
  // By close button
  $('.popup-close').not('.modal-close').on('click', function (e) {
    e.preventDefault();
    $(document.body).trigger('click');
  });


  // Tabs
  // Required: /js/src/plugins/jquery.easytabs.js
  $.each(GLOBAL.config.tabs, function (selector, options) {
    if ($(selector).length > 1) {
      $(selector).each(function () {
        $(this).easytabs(options);
      });
    } else {
      $(selector).easytabs(options);
    }
  });


  // Scroll links
  // Required: /js/src/plugins/jquery.scrollTo.js
  var scrollingInit = function (activator, options) {
    var options = options || $(activator).data('scrolling-options');
    var target = options.target || $(activator).data('scrolling-target') || '#' + $(activator).attr('href').split('#')[1],
        duration = options.duration || $(activator).data('scrolling-duration') || 200;
    delete options.target;
    delete options.duration;
    $.scrollTo(target, duration, options);
  };

  for (var i in GLOBAL.config.scrollings) {
    $(i).data('scrolling-options', GLOBAL.config.scrollings[i]).on('click', function (e) {
      e.preventDefault();
      scrollingInit(this);
    });
  }


  // Forms errors processing
  var inputs = '.input, select, textarea, .SSContainerDivWrapper',
      defaultValues = ['', 0];

  var $formErrors = $('.form .form-error');
  if ($formErrors.length) {
    setInterval(function () {
      $formErrors.find(inputs).each(function () {
        var $par = $(this).hasClass('SSContainerDivWrapper') ? $(this).parent().parent() : $(this).parent(),
            val = $(this).hasClass('SSContainerDivWrapper') ? $(this).parent().prev().val() : $(this).val(),
            activeState = $(this).hasClass('SSContainerDivWrapper') ? $(this).is(':visible') : $(this).is(':focus');
        if (activeState) {
          $par.data('wasError', true);
          $par.removeClass('form-error');
        } else {
          if ($par.data('wasError')) {
            $par.removeClass('form-error');
            if ($.inArray(val, defaultValues) != -1) {
              $par.addClass('form-error');
            }
          }
        }
      });
    }, 111);
  }




  // Custom scripts

  // Фиксации при скролле
  var $body = $(document.body),
      $headerFake = $('#header-fake'),
      $nav = $('nav:first'),
      $navFake = $('#nav-fake'),
      $content = $('#content');

  function fixerCalc() {
    var navHeight = $nav.outerHeight();

    $body.css('minHeight', $content.height());
    $navFake.css('height', navHeight);
    $content.css('top', $headerFake.outerHeight() + navHeight);

    fixer();
  }
  function fixer() {
    var scrollTop = $(window).scrollTop();

    if (scrollTop >= 0 && scrollTop <= $headerFake.outerHeight()) {
      $body.addClass('fixer');
      $headerFake.show();
      $('header').css('padding', '');
      $navFake.hide();
      $nav.removeClass('fixed');
    } else {
      $body.removeClass('fixer');
      $headerFake.hide();
      $('header').css({
        'paddingTop': $headerFake.outerHeight() - $nav.outerHeight(),
        'paddingBottom': $nav.outerHeight()
      });
      $navFake.show();
      $nav.addClass('fixed');
    }
  }
  fixerCalc();
  $(window).on('load ' + GLOBAL.actualResizer, fixerCalc).on('scroll', fixer);
  if (GLOBAL.isTouchscreen) {
    $(document).on('touchmove MSPointerMove pointermove', fixer);
  }

  // Подробнее
  $('.more-handler').on('click', function (e) {
    e.preventDefault();
    $(this).next('.more-content').toggle('fast');
  });

  // Вычисляет максимум
  function getMax(arr) {
    return parseInt(Math.max.apply(Math, $(arr).toArray()), 10);
  }

  // Считаем высоту блока, иначе будет всё плохо из-за того, что потомки абсолютны
  function fixHeight(block) {
    $(block && block.length ? block : '.block').each(function () {
      if (GLOBAL.windowSize[0] >= 954) {
        var maxAbs = getMax($(this).find('.abs').map(function () {
          return (parseInt($(this).css('top'), 10) + parseInt($(this).css('height'), 10));
        }));
        var curHeight = parseInt($(this).css('height'), 10);
        if (curHeight < maxAbs) {
          $(this).css('height', maxAbs);
        }
      } else {
        $(this).css('height', '');
      }
    });
  }
  fixHeight();
  $(window).on(GLOBAL.actualResizer, fixHeight);

  // "Ленивая" подгрузка картинок
  $('.block img').lazyload({
    effect: 'fadeIn',
    effectspeed: 200,
    load: function (img) {
      fixHeight($(img).parents('.block'));
    }
  });

  // Обработчики элементов работ и выставок
  if (!GLOBAL.isTouchscreen) {
    $('.block .fly').draggable(); // перетаскивание
    $('.block .fly').on('click dragstart', function () { // поверх всех
      var container = $(this).parent();
      var flies = container.find('.fly');
      if (flies.length <= 1) {
        return;
      }
      if (!container.data('maxIndex')) {
        var curMax = getMax(flies.map(function () {
          return $(this).css('z-index');
        }));
        container.data('maxIndex', curMax);
      }
      container.data('maxIndex', container.data('maxIndex') + 1);
      $(this).css('z-index', container.data('maxIndex'));
    });
  }

  // Для видео
  $('.block .media a').on('click', function (e) {
    e.preventDefault();
    var self = this;
    $(this).fadeOut('normal', function () {
      $($(self).attr('href')).show();
    });
  });

  // Colorbox
  function colorbox() {
    $('.block .curators a').colorbox({ // для текстов кураторов
      inline: true,
      speed: 500,
      initialWidth: 100,
      initialHeight: 100,
      width: '100%',
      maxWidth: 503,
      maxHeight: '96%',
      minWidth: 320,
      current: '',
      next: '',
      previous: '',
      opacity: .75
    });
    if (GLOBAL.windowSize[0] >= 954) {
      $('.block .image a').off('click').colorbox({ // для картинок
        speed: 500,
        maxWidth: 954,
        maxHeight: '92%',
        minWidth: 320,
        current: '',
        next: '',
        previous: '',
        opacity: .75,
        onComplete: function () {
          $('#cboxTitle').css('width', $('#cboxLoadedContent').width());
        }
      });
    } else {
      $('.block .image a').on('click', function (e) {
        e.preventDefault();
        return false;
      });
    }
  }
  colorbox();
  $(window).on(GLOBAL.actualResizer, colorbox);

});